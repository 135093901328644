export const environment = {
  production: true,
  baseApi: "https://api.staging.ohmymenu.com",
  baseApp: "https://menu.staging.ohmymenu.com/home",
  bucket: "com-ohmymenu-admin-staging",
  release: "0.2.35",
  name: "staging",
  firebase: {
    apiKey: "AIzaSyBZM3lyTqD7CJnFeXaGe2E-zaXK3Ahtq0M",
    authDomain: "com-ohmymenu.firebaseapp.com",
    databaseURL: "https://com-ohmymenu.firebaseio.com",
    projectId: "com-ohmymenu",
    storageBucket: "com-ohmymenu.appspot.com",
    messagingSenderId: "229737233171",
    appId: "1:229737233171:web:03f0ca38027a7c543838e6",
    measurementId: "G-MWE8Z11FE3",
  },
  stripeApiKey: "sk_test_51Ict0KITe39Znbib9RC8s8VBAlrWLKYdbnmxJONcAYIPwI8IViZG7lOGTQSU2QdCyQNTiqjoDPwwFqANNg8PKUTq00XUfCs4i6",
  stripePublishableKey: "pk_test_51Ict0KITe39ZnbibhtJ7NgHyQoGYrtbF5SgFdIWA3FdYkQY4ZZRirFVBdbkoNKCE9VCc62jCiz8y3XjLixPgoNKm00Ik6RWxOP",
  googleClientId: "229737233171-8v592h6as057k4dqo07vt1257t5upi60.apps.googleusercontent.com",
};
